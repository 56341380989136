@at-root {
  // Headline
  @font-face {
    font-family: "GothamRndBold";
    src:
      url("../../fonts/themes/agrology/Gotham-Rounded/GothamRnd-Bold.woff") format("woff"),
      url("../../fonts/themes/agrology/Gotham-Rounded/GothamRnd-Bold.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }

  // Basic text
  @font-face {
    font-family: "GothamRnd";
    src:
      url("../../fonts/themes/agrology/Gotham-Rounded/GothamRnd-Book.woff") format("woff"),
      url("../../fonts/themes/agrology/Gotham-Rounded/GothamRnd-Book.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "GothamRnd";
    src:
      url("../../fonts/themes/agrology/Gotham-Rounded/GothamRnd-Medium.woff") format("woff"),
      url("../../fonts/themes/agrology/Gotham-Rounded/GothamRnd-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
  }
  // @font-face {
  //   font-family: "GothamRnd";
  //   src:
  //     url("../../fonts/themes/agrology/Gotham-Rounded/GothamRnd-Bold.woff") format("woff"),
  //     url("../../fonts/themes/agrology/Gotham-Rounded/GothamRnd-Bold.woff2") format("woff2");
  //   font-weight: bold;
  //   font-style: normal;
  // }
}

$theme-config: (
  primary: #f98a3c,
  font-heading: #{"GothamRndBold, Lato, Helvetica, sans-serif"},
  font-basic: #{"GothamRnd, Lato, Helvetica, sans-serif"},
  success: #549b22,
  logo-path: url("/assets/img/theme/agrology/logo.svg"),
  logo-black-path: url("/assets/img/theme/agrology/logo.svg"),
  logo-white-path: url("/assets/img/theme/agrology/logo-w.svg"),
  logo-aspect-ratio: calc(464 / 100)
);

@include generateSASSThemeVariables($theme-config);
@include generateCSSThemeVariables();

@import "../bootstrap_variables";
@import "../agree_styles";

.landing-bg {
  > .overlay {
    background-color: rgba(0, 0, 0, 0.4);
  }
  > .background-images {
    > .field {
      background-image: none;
      // background-position: left center;
      background-color: #fff;
    }
    > .teamwork {
      background-image: url("/assets/img/home/bg-santander@4k.png");
      background-position: right bottom;
      width: 100%;
      background-size: 70%;
      background-color: #fff;
    }
  }
}

.navbar {
  .navbar-brand {
    .logo {
      // background-size: 90%;
      height: 25px !important;
    }
  }
}

@media (max-width: 767px) {
  .navbar {
    .navbar-brand {
      .logo {
        width: 17px !important;
        // background-size: cover;
      }
    }
  }
}
